var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"wrapper"},[_vm._m(0),_c('br'),_c('div',{staticClass:"life-gambo"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.settings.terms)}}),_vm._m(1),_c('p',[_vm._v(" You also agree that we may provide all legal communications and notices to you electronically by posting them on our website or, at our election, by sending an e-mail to the e-mail address you provided to us when you registered at our website. ")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"default-dt"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"title129"},[_c('h2',[_vm._v("Terms and Conditions")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Acceptance of Terms of Use")]),_c('br'),_vm._v(" Please read these Terms of Use carefully. These Terms of Use govern your access and use of this Site. By accessing or using this Site you agree to be bound by these Terms of Use and to any additional guidelines, restrictions, or rules that may be posted in connection with specific sections or services of this Site. All such additional posted guidelines, restrictions, or rules are hereby incorporated by reference into these Terms of Use. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Copyrighted Materials for Limited Use")]),_c('br'),_vm._v(" This Site contains graphics, photographs, images, document layouts, text, fonts, software tools, and other information (referred to here in as “Content”). This Site and all Content are the copyrighted property of bijleebox.com and its subsidiaries or the copyrighted property of parties from whom bijleebox.com has licensed such property. All rights in this Site and its Content are reserved worldwide. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Use of Site")]),_c('br'),_vm._v(" You are granted permission to access and use this Site and its Content for the sole purpose of evaluating, and ordering products solely through bijleebox.com. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Indemnification")]),_c('br'),_vm._v(" You agree that you shall indemnify and defend bijleebox.com and all parties from whom bijleebox.com has licensed portions of Content, and their directors, officers, and employees, against all claims, liability, damages, costs, and expenses. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Disclaimer of Warranty")]),_c('br'),_vm._v(" This site is provided on an ‘AS IS’ and ‘AS AVAILABLE’ basis. We make no representations or warranties of any kind, whether express or implied, as to the operation of this site or the information, content, materials, or products included on it. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Limitation of Liability")]),_c('br'),_vm._v(" In no event shall bijleebox.com or its licensors, suppliers, or vendors, their officers, directors, employees, or agents be liable for any special, incidental, indirect, or consequential damages of any kind, or for any damages whatsoever resulting from loss of use, data, or profits. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Error in Product Prices on Website")]),_c('br'),_vm._v(" Despite our best intentions and efforts, erroneous pricings may happen in a limited number of products on our website. If the item's correct price is lower than our published price, we shall invoice the lower amount. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Site Feedback")]),_c('br'),_vm._v(" Any comments, suggestions, proposals, or other feedback (collectively “Feedback Information”) provided to bijleebox.com shall become the exclusive property of bijleebox.com. ")])
}]

export { render, staticRenderFns }