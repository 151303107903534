<template>
  <section>
    <!-- Body Start -->
    <div class="wrapper">
      <div class="default-dt">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="title129">
                <h2>Terms and Conditions</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="life-gambo">
        <div class="container">
          <div class="row">
            <div v-html="settings.terms"></div>

            <p>
              <strong>Acceptance of Terms of Use</strong><br />
              Please read these Terms of Use carefully. These Terms of Use
              govern your access and use of this Site. By accessing or using
              this Site you agree to be bound by these Terms of Use and to any
              additional guidelines, restrictions, or rules that may be posted
              in connection with specific sections or services of this Site. All
              such additional posted guidelines, restrictions, or rules are
              hereby incorporated by reference into these Terms of Use.
            </p>

            <p>
              You also agree that we may provide all legal communications and
              notices to you electronically by posting them on our website or,
              at our election, by sending an e-mail to the e-mail address you
              provided to us when you registered at our website.
            </p>

            <p>
              <strong>Copyrighted Materials for Limited Use</strong><br />
              This Site contains graphics, photographs, images, document
              layouts, text, fonts, software tools, and other information
              (referred to here in as “Content”). This Site and all Content are
              the copyrighted property of bijleebox.com and its subsidiaries or
              the copyrighted property of parties from whom bijleebox.com has
              licensed such property. All rights in this Site and its Content
              are reserved worldwide.
            </p>

            <p>
              <strong>Use of Site</strong><br />
              You are granted permission to access and use this Site and its
              Content for the sole purpose of evaluating, and ordering products
              solely through bijleebox.com.
            </p>

            <p>
              <strong>Indemnification</strong><br />
              You agree that you shall indemnify and defend bijleebox.com and
              all parties from whom bijleebox.com has licensed portions of
              Content, and their directors, officers, and employees, against all
              claims, liability, damages, costs, and expenses.
            </p>

            <p>
              <strong>Disclaimer of Warranty</strong><br />
              This site is provided on an ‘AS IS’ and ‘AS AVAILABLE’ basis. We
              make no representations or warranties of any kind, whether express
              or implied, as to the operation of this site or the information,
              content, materials, or products included on it.
            </p>

            <p>
              <strong>Limitation of Liability</strong><br />
              In no event shall bijleebox.com or its licensors, suppliers, or
              vendors, their officers, directors, employees, or agents be liable
              for any special, incidental, indirect, or consequential damages of
              any kind, or for any damages whatsoever resulting from loss of
              use, data, or profits.
            </p>

            <p>
              <strong>Error in Product Prices on Website</strong><br />
              Despite our best intentions and efforts, erroneous pricings may
              happen in a limited number of products on our website. If the
              item's correct price is lower than our published price, we shall
              invoice the lower amount.
            </p>

            <p>
              <strong>Site Feedback</strong><br />
              Any comments, suggestions, proposals, or other feedback
              (collectively “Feedback Information”) provided to bijleebox.com
              shall become the exclusive property of bijleebox.com.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Body End -->
  </section>
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  data() {
    return {
      settings: {},
    };
  },
  async mounted() {
    let settings = await axios.get("settings");
    this.settings = settings.data.data[0];
  },
};
</script>
<style scoped>
p {
  font-size: 16px;
  text-align: start;
}
ul {
  font-size: 16px;
  list-style-type: disc;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
  line-height: 1.6;
}
.life-gambo {
  /* padding-top: 5px; */
  padding: 5px 0;
}
.col-lg-12 {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 39px;
}
.title129 h2 {
  font-size: 49px;
  font-weight: 700;
  margin-top: 16px !important;
  color: white;
  text-align: center;
  line-height: 30px;
}
</style>
