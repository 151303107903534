<template>
  <section>
    <div class="sign-inup">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="sign-form">
              <div class="sign-inner">
                <div class="sign-logo" id="logo">
                  <img src="images/logo.svg" alt="" />
                  <img class="logo-inverse" src="images/dark-logo.svg" alt="" />
                </div>
                <div class="form-dt">
                  <div class="form-inpts checout-address-step">
                    <form @submit.prevent="passwordResetEmail">
                      <div class="form-title">
                        <h6>Send Reset Password Email</h6>
                      </div>
                      <div v-if="isOtpMatch != true" class="form-group pos_rel">
                        <input
                          id="phone[number]"
                          name="phone"
                          type="text"
                          placeholder="Enter email"
                          class="form-control lgn_input"
                          required=""
                          v-model="form.email"
                        />
                        <i class="uil uil-mobile-android-alt lgn_icon"></i>
                      </div>
                      <div v-if="isOtpSend" class="form-group pos_rel">
                        <input
                          id="phone[otp]"
                          name="otp"
                          type="text"
                          placeholder="Enter Otp"
                          class="form-control lgn_input"
                          required=""
                          v-model="form.otp"
                        />
                        <i class="uil uil-key-skeleton-alt lgn_icon"></i>
                        <span style="color: red">{{ errors.otp }}</span>
                      </div>
                      <div v-if="isOtpMatch" class="form-group pos_rel">
                        <input
                          id="phone[password]"
                          name="password"
                          type="text"
                          placeholder="Enter new password"
                          class="form-control lgn_input"
                          v-model="form.password"
                        />
                        <i class="uil uil-key-skeleton-alt lgn_icon"></i>
                        <span style="color: red">{{ errors.password }}</span>
                      </div>
                      <div v-if="isOtpMatch" class="form-group pos_rel">
                        <input
                          id="phone[re-password]"
                          name="password"
                          type="text"
                          placeholder="Re-Enter password"
                          class="form-control lgn_input"
                          v-model="form.password_confirmation"
                        />
                        <i class="uil uil-key-skeleton-alt lgn_icon"></i>
                        <span style="color: red">{{
                          errors.password_confirmation
                        }}</span>
                      </div>
                      <button class="login-btn hover-btn" type="submit">
                        <span v-if="isOtpMatch"> Change Password</span>
                        <span v-else-if="isOtpSend">OK</span>
                        <span v-else> Send Email</span>
                      </button>
                      <span
                        v-if="loading && form.email != '' && form.otp == '' && form.password == '' && form.password_confirmation == ''"
                        >Sending...</span
                      >
                      <span
                        v-if="loading && form.otp != '' && form.password == '' && form.password_confirmation == ''"
                        >Checking OTP...</span
                      >
                      <span
                        v-if="loading && form.password != '' && form.password_confirmation != '' && loginform.email == '' && loginform.password == ''"
                        >Changing password...</span
                      >
                      <span
                        v-if="loading && loginform.email != '' && loginform.password != ''"
                        >Logging in...</span
                      >
                    </form>
                  </div>
                  <div class="password-forgor">
                    <router-link to="/Login">Login Now</router-link>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
import { mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
        password_confirmation: "",
        otp: "",
      },
      loading: false,
      isOtpSend: false,
      isOtpMatch: false,
      loginform: {
        email: "",
        password: "",
      },
      errors: {},
    };
  },
  methods: {
    ...mapActions({
      logIn: "auth/logIn",
    }),
    async passwordResetEmail() {
      this.loading = true;
      try {
        let response = await axios.post("/forgot_password", this.form);
        this.isOtpSend = true;
        if (response.data.data.otp == this.form.otp) {
          this.isOtpMatch = true;
          this.isOtpSend = false;
        }
        let passwordChange = response.data.isMatch;
        if (passwordChange) {
          try {
            const ipInfo = await axios.get("https://ipinfo.io/json");
            const deviceInfo = navigator.userAgent;
            this.loginform.device_name = this.getDeviceName(deviceInfo);
            this.loginform.location = ipInfo.data.city;
            this.loginform.login_time = new Date().toLocaleString();
          } catch (error) {
            console.log('Error: ', error);
          }
          this.loginform.email = this.form.email;
          this.loginform.password = this.form.password;
          this.loginform.is_password_change = true;
          this.isLoging = true;
          await this.logIn(this.loginform);
          this.$router.push("/");
        }
        this.loading = false;
      } catch (error) {
        this.errors = error.response.data.errors;
        this.loading = false;
      }
    },
    getDeviceName(userAgent) {
      if (userAgent.match(/(iPhone|iPod|iPad)/)) {
        return "Apple Device";
      } else if (userAgent.match(/Android/)) {
        return "Android Device";
      } else if (userAgent.match(/Windows/)) {
        return "Windows PC";
      } else if (userAgent.match(/Macintosh/)) {
        return "Macintosh";
      } else {
        return "Unknown Device";
      }
    },
  },
};
</script>
