<template>
  <section>
    <!-- Body Start -->
    <div class="wrapper">
      <div class="default-dt">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="title129">
                <h2>About Us</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="life-gambo">
        <div class="container">
          <!-- <div class="row align-items-start">
            <div class="col-lg-12">
              <div class="about-content">
                <h3>About BijleeBox</h3>
                <p v-if="settings.about" v-html="formattedContent"></p>
                <p v-else>Loading content...</p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-img">
                <img :src="vectorImage" alt="About Bijlee Box Image" />
              </div>
            </div>
          </div> -->

          <!-- Additional Content Section -->
          <div class="about-text">
            <h3>Why Choose Bijleebox?</h3>
            <p>
              We think it is in the best interest of our customers that rather
              than telling them about us from our perspective, they deserve to
              know why they should choose
              <a href="https://bijleebox.com"></a>BIJLEEBOX.
            </p>
            <p>
              In 2023, two friends and business associates started discussing
              problems in the Electrical Retail market. There were a few
              challenges, like selling without proper invoices, duplicate
              material in the market, retailers selling what they want to sell
              and not focusing on what the customer needs, and more.
            </p>
            <p>
              After researching for six months, we identified problems and found
              solutions. We did on-ground and online research. After many
              meetings & brainstorming with experts from various fields, two
              people matched our Vision for
              <a href="https://bijleebox.com"></a> BIJLEEBOX. Now, a team with
              technical, commercial, IT, and logistics expertise is working
              together to provide our customers with the best pricing and
              experience.
            </p>

            <h3>Our Team:</h3>
            <div class="team-member">
              <h4>Vivek Rana:</h4>
              <p>
                He is behind the idea of bringing an electrical retail store to
                every customer through an online platform. He is a Lawyer,
                Electrical Contractor, and Developer who observes the challenges
                people face daily and tries to find solutions for them.
              </p>
            </div>
            <div class="team-member">
              <h4>Raghav Anand:</h4>
              <p>
                With over 30 years in the market, he is the second generation
                managing an Electrical Distributor business. Mr. Raghav has
                expertise in sales and market study of this sector and
                formulated the business plan with a long-term vision.
              </p>
            </div>
            <div class="team-member">
              <h4>Vijaykumar Kadiyam:</h4>
              <p>
                With 12+ years of experience in IT and development, Mr. Vijay
                turned our ideas into a user-friendly website. He created one of
                the most creative and innovative websites in the electrical
                retail market.
              </p>
            </div>
            <div class="team-member">
              <h4>Rakesh Kumar:</h4>
              <p>
                An expert in logistics with over 10 years of experience in the
                merchant navy, Mr. Rakesh ensures that our logistics are handled
                smoothly, delivering all our promises to customers with
                responsibility and precision.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Body End -->
  </section>
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  data() {
    return {
      settings: {},
      vectorImage: "images/bijleebox.webp", // Vector image placeholder
    };
  },
  computed: {
    formattedContent() {
      // Format the content dynamically to ensure clean display
      return this.settings.about ? this.settings.about.trim() : "";
    },
  },
  async mounted() {
    try {
      const response = await axios.get("settings");
      this.settings = response.data.data[0];
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  },
};
</script>

<style>
/* About Us Page Styles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.container {
  max-width: 100%;
  padding: 0 15px;
}

.life-gambo {
  padding: 30px 0;
}

.title129 h2 {
  font-size: 49px;
  font-weight: 500;
  margin-top: 16px !important;
  color: white;
  text-align: center;
  line-height: 40px;
}

.default-title {
  text-align: left;
}

.about-content {
  background-color: #f4f4f4;
  padding: 30px;
  border-radius: 8px;
}

.about-content h3,
.about-text h3 {
  font-size: 27px;
  color: #2b2f4c;
  margin-top: 30px;
  font-weight: bold;
}

.about-content p,
.about-text p {
  font-size: 16px;
  line-height: 1.4;
  text-align: justify;
  margin-bottom: 20px;
}

.team-member h4 {
  font-size: 22px;
  margin-top: 20px;
  color: #f55d2c;
}

.team-member p {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 10px;
}

.about-img img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 20px auto;
  border-radius: 8px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.row {
  margin: 0;
}

.default-title {
  text-align: left;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .title129 h2 {
    font-size: 36px;
  }

  .about-img img {
    max-width: 90%;
  }

  .about-content {
    padding: 20px;
  }

  .team-member {
    margin-bottom: 20px;
  }

  .team-member h4 {
    font-size: 18px;
  }

  .about-text h3 {
    font-size: 28px;
  }
}
</style>
